const CryptoJS = require("crypto-js");
const {
  REACT_APP_ENCRYPTION_KEY: key,
  REACT_APP_ENCRYPTION_IV: iv,
} = require("../config/envConfig");

export const encryptData = (data) => {
  const keyUtf8 = CryptoJS.enc.Utf8.parse(key);
  const ivUtf8 = CryptoJS.enc.Utf8.parse(iv);
  return CryptoJS.AES.encrypt(JSON.stringify(data), keyUtf8, {
    iv: ivUtf8,
    mode: CryptoJS.mode.CBC,
  }).toString();
};

export const decryptData = (ciphertext) => {
  const keyUtf8 = CryptoJS.enc.Utf8.parse(key);
  const ivUtf8 = CryptoJS.enc.Utf8.parse(iv);
  const decrypted = CryptoJS.AES.decrypt(ciphertext, keyUtf8, {
    iv: ivUtf8,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};
