import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { setMsalAd } from '../../store/slices/MsalSlice';
import { Breadcrumb } from '../Breadcrumb';
import { adsConfig } from '../../config/AdsConfig';
import selectedIcon from '../../images/icons/Circle_Check.png';
import { REACT_APP_JV_ENABLE } from '../../config/envConfig'
import { parseString } from 'src/utils/claimUtils';
const parsedJvEnableMap  = parseString(REACT_APP_JV_ENABLE)

const MsalAdConfig: FC = () => {
    const dispatch = useDispatch();
    const { msalAd } = useSelector(({ msal }) => msal);
    const [activeAD, setActiveAD] = useState(msalAd);

    const submitAD = () => {
        dispatch(
            setMsalAd(activeAD)
        );
    }
    const adsConfigMap = adsConfig.filter(v=> {
        return parsedJvEnableMap[v.value]
    })
    return (
        <div className="wrapper">
            <p className="heading">Link your employee account to Blue</p>
            <Breadcrumb highlight='1'/>           
            {/* <div className="sub-heading">Select your employer</div> // commented as suggested in new figma design */}
            <div className="boxWrapper">                    
                {adsConfigMap.map((adConfig, idx) => (      

                        <div 
                            className="box"
                            key={idx}
                            onClick={(e) => {
                                setActiveAD(adConfig.value);
                                let list = document.querySelector('.active') as HTMLInputElement;
                                list?.classList.remove('active');                                
                                e.currentTarget?.classList.add('active');
                            }}
                            >
                            <span className="selectedIcon"><img src={selectedIcon} alt="Selected"/></span>
                            {adConfig.name}
                        </div>
                ))}
            </div>
            <div className="mob-bottom-wrapper">
                <Button onClick={submitAD} className="custom-btn">Next</Button>
            </div>
        </div>
    );
}

export default MsalAdConfig;