import React from 'react';
import Accordion from '../../components/Accordion';
import parse from 'html-react-parser';

const FAQ = () => {    
    const accordionData = [
        {
          title: 'Why do I need to link my employee ID to Blue?',
          content: `By connecting your employee ID to Blue, you can conveniently access your employee ID, business card, employee offers, and Blue rewards using just one app. This means you won't have to manage multiple apps or accounts anymore.`
        },
        {
          title: 'What will happen when I link my employee ID to Blue?',
          content: `Linking your employee ID to Blue allows you to access all the information and offers from your "Link App" within the Blue App.`
        },
        {
          title: 'As an employee, will I need to use both the Link app and the Blue App?',
          content: `Once your employee account is linked to your Blue account, you'll only need the Blue App to access your employee information, benefits, and other offers – streamlining everything into one app.`
        },
        {
          title: 'I’m a registered Blue App member, but my account is connected to my personal number. Can I still link it to my employee ID?',
          content: `Absolutely, you can link your personal Blue account with your employee ID. This integration enables you to conveniently access both your personal and employee benefits using just one app.`
        },
        {
          title: 'I don’t have Blue App, what should I do?',
          content: `If you haven't registered with Blue yet, please download the "Blue Rewards" app from the App Store or Google Play Store. Once you've downloaded and registered, you can proceed to link your employee ID with Blue App.`
        },
        {
          title: 'Can I access my employee details and offers through the Blue app?',
          content: `
          By linking your employee ID with Blue, you will be able to: 
          <br>
          <ul>
            <br>
            <li>	View and share your employee ID and business card. </li>
            <br>
            <li>	Redeem employee offers using your employee ID barcode. </li>
            <br>
            <li>	Access employee offers at Dubai Festival City Mall. </li>
            <br>
            <li>	Enjoy a wide range of additional offers and benefits available through Blue App. </li>
          </ul>
          `
        },
        {
          title: 'Will I continue to have access to the Link app?',
          content: `The Link App will be decommissioned in the near future. We recommend that you make the switch to Blue App as soon as possible, as beyond that point, all employee offers and details will exclusively be available within the Blue App.`
        }
      ];
    return (
        <>
            <div className="body_wrapper tandC">
                <div className="wrapper">
                    <p className='title'>FAQ</p>                    
                </div>
                <div className="accordion">
                    {accordionData.map(({ title, content }) => (
                        <Accordion title={title} content={parse(content)} />
                    ))}                    
                </div>
                <p className="contact">If you encounter any issues or require further assistance, please don't hesitate to reach out to us at <a href="mailto:help@mybluerewards.com">help@mybluerewards.com</a>. We're here to help!</p>
            </div>
        </>
    );
};

export default FAQ;