import { RouteObject } from "react-router-dom";
import { AuthenticatedTemplate } from "@azure/msal-react";
import Profile from "../pages/profile";
import PageNotFound from "../pages/pageNotFound";
import TermsAndCondition from "../pages/termsAndCondition";

const AuthenticatedRoutes: RouteObject = {
    children: [
        {
          path: '/profile',
          element: (
            <AuthenticatedTemplate>
                <Profile />
            </AuthenticatedTemplate>
            )
        },
        {
            path: '/pageNotFound',
            element: (
              <AuthenticatedTemplate>
                  <PageNotFound />
              </AuthenticatedTemplate>
            )
        },
        {
            path: '/termsAndCondition',
            element: (
              <AuthenticatedTemplate>
                  <TermsAndCondition />
              </AuthenticatedTemplate>
            )
        }
      ]
};

export default AuthenticatedRoutes;