import axios, { AxiosRequestConfig } from "axios";
import { decryptData, encryptData } from "src/utils/crypto";
const { API_KEY:apikey } = require("../config/apiConfig");

const generateRandomString = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2)
}

const getHeaders = (config) => {
  if(!config && !config.headers)
  config.headers = {}

  return config.headers = {
    "Content-Type": "application/json",
    "Channel-Id" : "BLUE",
    "X-Frame-Options": "DENY",
    "X-XSS-Protection": "1; mode=block",
    "X-Content-Type-Options": "nosniff",
    "Referrer-Policy": "no-referrer",
    "Content-Security-Policy": "default-src 'self' 'unsafe-inline' 'unsafe-eval' data: *",
    "Cache-Control": "no-store",
    ...config?.headers,
    apikey
}
}

axios.interceptors.response.use(
  (res) => {
    try {
      const str = decryptData(res.data.data)
      res.data = JSON.parse(str);
      return res;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  (err) => {
     return Promise.reject(err);
  }
);

class HttpClient {
  async get(url: string, config: AxiosRequestConfig<any> | undefined = {}) {
    url = `${url}&randomId=${generateRandomString()}`
    config.headers = getHeaders(config)
    return await axios.get(url, { ...config,  })
  }
  async post(url: string, body: any, config: AxiosRequestConfig<any> | undefined = {}, isEncrypt: boolean = false) {
    if(isEncrypt)
    body = {data : encodeURIComponent(encryptData(body))}
    config.headers = getHeaders(config)
    return await axios
      .post(url, body, { ...config,  })
  }
  async put(url: string, body: any, config: AxiosRequestConfig<any> | undefined = {}, isEncrypt?: boolean) {
    return await axios
      .put(url, body, { ...config,  })
  }
  async delete(url: string, body: any, config: AxiosRequestConfig<any> | undefined = {}) {
    return await axios
      .delete(url, config)
      .then((res) => {
        console.error("delete", res);
        return res;
      })
      .catch((error) => {
        console.error("delete", error);
        return error?.response ? error.response : error;
      });
  }
}

const axiosClient = new HttpClient();
export default axiosClient