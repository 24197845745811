import { createSlice } from '@reduxjs/toolkit';

interface MsalState {
    msalAd: null | string;
}

const initialState: MsalState = {
    msalAd: null,
};

const MsalSlice = createSlice({
    name: 'msal',
    initialState,
    reducers: {
        setMsalAd: (state, action) => {
            const { payload: msalAd } = action;
            return { ...state, msalAd };
        }
    },
});

export const { setMsalAd } = MsalSlice.actions;

export default MsalSlice;
