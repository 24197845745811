import React, { Component, useState } from 'react';
import oopsIcon from '../../images/icons/oopsIcon.png';
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
    let navigate = useNavigate();
    const tryAgain = ()=> {
        navigate('/');
    }
    return (
        <>
            <div className="blue_body_wrapper">
                <div className="body_wrapper blue">
                    <div className="wrapper">
                        <img src={oopsIcon} alt="Oops Icon" className="icon"/>
                        <div className="text">
                            <p className="heading">Ooops</p>
                            <span className="sub-heading">Something went wrong, please check your information and try again later</span>
                        </div>
                    </div>
                    <Button onClick={tryAgain} className="custom-btn white">Try Again</Button>
                </div>
            </div>
        </>
    );
};

export default PageNotFound;