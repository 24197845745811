import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Nav, Navbar, Dropdown, DropdownButton } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import { setMsalAd } from '../../store/slices/MsalSlice';

export const NavigationBar = () => {
    const dispatch = useDispatch();
    const { instance, accounts } = useMsal();

    const handleLogoutPopup = () => {
        instance.logoutPopup({
            mainWindowRedirectUri: '/', // redirects the top level app after logout
            account: accounts?.[0] ?? null
        }).then((res) => {
            dispatch(
                setMsalAd(null)
            );
        })
    };

    return (
        <>
            <Navbar collapseOnSelect expand="sm" bg="primary" variant="dark">
                <Navbar.Brand as={Link} to="/" className="mx-3">
                    {/* Microsoft identity platform */}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {/* <Nav.Link as={Link} className="navbarButton" to="/profile">
                            Profile
                        </Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>
                <div className="mx-3">
                    <DropdownButton variant="warning" drop="start" title="Sign Out">
                        <Dropdown.Item as="button" onClick={handleLogoutPopup} title="sign out">
                            Sign out
                        </Dropdown.Item>
                    </DropdownButton>
                </div>
            </Navbar>
        </>
    );
};
