export const {
    REACT_APP_EMP_CODE,
    REACT_APP_BLUE_CODE,
    REACT_APP_AFG_MSAL_CLIENT_ID,
    REACT_APP_AFG_MSAL_AUTHORITY,
    REACT_APP_AFG_MSAL_REDIRECT_URI,
    REACT_APP_AFG_MSAL_POST_LOGOUT_REDIRECT_URI,
    REACT_APP_DIS_MSAL_CLIENT_ID,
    REACT_APP_DIS_MSAL_AUTHORITY,
    REACT_APP_DIS_MSAL_REDIRECT_URI,
    REACT_APP_DIS_MSAL_POST_LOGOUT_REDIRECT_URI,
    REACT_APP_UAS_MSAL_CLIENT_ID,
    REACT_APP_UAS_MSAL_AUTHORITY,
    REACT_APP_UAS_MSAL_REDIRECT_URI,
    REACT_APP_UAS_MSAL_POST_LOGOUT_REDIRECT_URI,
    REACT_APP_MS_URL,
    REACT_APP_GET_BLUE,
    REACT_APP_POST_EMPLOYEE,
    REACT_APP_GET_EMPLOYEE,
    REACT_APP_API_KEY,
    REACT_APP_ENCRYPTION_KEY,
    REACT_APP_ENCRYPTION_ALGORITHM,
    REACT_APP_ENCRYPTION_IV,
    REACT_APP_EIB_MSAL_CLIENT_ID,
    REACT_APP_EIB_MSAL_AUTHORITY,
    REACT_APP_EIB_MSAL_REDIRECT_URI,
    REACT_APP_EIB_MSAL_POST_LOGOUT_REDIRECT_URI,
    REACT_APP_JV_ENABLE
} = process.env;