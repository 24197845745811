import React, { Component, useState } from 'react';
import yayyIcon from '../../images/icons/yayyIcon.png';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import yayyImage from '../../images/yayyImg.png';
import barCode from '../../images/icons/barcode.png';

const Profile = () => {
    const {state} = useLocation();
    // const { mobileNumber, emailId } = state;
    
    return (
        <>
            <div className="blue_body_wrapper">
                <div className="body_wrapper blue">
                    <div className="wrapper profile">                    
                            <img src={yayyIcon} alt="Yayy Icon" className="icon"/>
                            <div className="text">
                                <p className="heading">Congratulations!</p>
                                {/* <span className="sub-heading">{state.mobileNumber} is now linked to {state.emailId}. You now have access to your employee offers and business card on Blue!</span> */}
                                <span className="sub-heading">You're all set to unlock the full potential of the Blue app. Access your exclusive employee offers and digital business card now.</span>
                            </div>                    
                        <div className="successWrap">
                            <div className="barWrap">
                                <img src={barCode} alt="Barcode" />
                                <p>Click on the barcode on the home page to see your ID</p>
                            </div>
                            <img src={yayyImage} alt="Success Image" className="successImg"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;