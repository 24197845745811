import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserAuthError } from '@azure/msal-browser';
import { AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { NavigationBar } from '../navigation';
import { setMsalAd } from '../../store/slices/MsalSlice';

export const PageLayout = (props) => {
    const dispatch = useDispatch();
    const { instance, accounts, inProgress } = useMsal();
    const msalConfig = instance.getConfiguration();
    const activeAccount = accounts?.[0] ?? null;
    const intractionAPICall = async () => {
        console.log(inProgress);
        if (!activeAccount && instance) {
           await instance
                .loginPopup({
                    scopes: ["User.Read"],
                    redirectUri: msalConfig.auth.redirectUri,
                })
                .catch((error) => {
                    console.log(error)
                    localStorage.clear();
                    sessionStorage.clear();
                    if (error instanceof BrowserAuthError && ['user_cancelled'].includes(error.errorCode)) {
                        dispatch(
                            setMsalAd(null)
                        );
                    }
                });
        }
    }
    useEffect(() => {
        intractionAPICall()
    }, []);

    return (
        <>
        {
            activeAccount ?
            <AuthenticatedTemplate>
            {/* <NavigationBar /> */}
            {props.children}
        </AuthenticatedTemplate>
        :
          <UnauthenticatedTemplate>
                {/* Authenticating..... */}
            </UnauthenticatedTemplate>
        }
     
          
            
        </>
    );
};
