import React from 'react';
import { useLocation } from 'react-router-dom';

const TermsAndCondition = () => {    
    const location = useLocation();
    const country = location.hash.replace('#','');
    const data = {
        'UAE' : `I grant consent to Blue Rewards SPC LLC to collect, retain, use and disclose information associated with my membership or otherwise provided to Al-Futtaim Private Company LLC or any other members of Al Futtaim Group of companies or any Blue Programme partners pursuant to the Blue Programme, including the above-mentioned information and any information relating to my use of the Blue App and/or the Blue Programme.`,
        'KSA' : `I grant consent to AlFuttaim Holding International, AlFuttaim Global Trading to collect, retain, use and disclose information associated with my membership or otherwise provided to Al Futtaim Golden Trading Company, Al Futtaim Engineering & Technologies Ltd.,Orient Marketing & Trading Co. LLC , Al Futtaim First Commercial LTD KSA, Al Futtaim Logistics Company LTD, Al Futtaim Auto & Machinery Limited, Al Futtaim International Holding, Al Futtaim Pioneer Trading Company or any other members of Al Futtaim Group of companies or any Blue Programme partners pursuant to the Blue Programme, including the above-mentioned information and any information relating to my use of the Blue App and/or the Blue Programme.`,
        'Oman' : `I grant consent to Al Futtaim Sons to collect, retain, use and disclose information associated with my membership or otherwise provided to Al Futtaim Sons LLC, Al Futtaim Auto Centres LLC, Orient Insurance (Branch), Oman Marketing & Services LLC , Al Futtaim Al Jadida LLC or any other members of Al Futtaim Group of companies or any Blue Programme partners pursuant to the Blue Programme, including the above-mentioned information and any information relating to my use of the Blue App and/or the Blue Programme.`,
        'Kuwait' : `I grant consent to Al Futtaim Kuwait for Central Markets W.L.L to collect, retain, use and disclose information associated with my membership or otherwise provided to Al Futtaim Kuwait for Central Markets WLL or any other members of Al Futtaim Group of companies or any Blue Programme partners pursuant to the Blue Programme, including the above-mentioned information and any information relating to my use of the Blue App and/or the Blue Programme.`,
        'Singapore' : `I grant consent to Al Futtaim Retail Company (Singapore) Pte Ltd to collect, retain, use and disclose information associated with my membership or otherwise provided to BACCARAT INTERNATIONAL (PTE.) LIMITED, RSH BRANDS PRIVATE LIMITED, PRASAN PTE. LTD., MONTEZ PTE. LTD., GAGAN HOLDINGS PTE LTD, ARYAN (SEA) PRIVATE LIMITED, Armaan PTE. Ltd, RSH HOLDINGS PTE LTD or any other members of Al Futtaim Group of companies or any Blue Programme partners pursuant to the Blue Programme, including the above-mentioned information and any information relating to my use of the Blue App and/or the Blue Programme.`,
        'Malaysia' : `I grant consent to Al Futtaim Holdings Malaysia Sdn Bhd to collect, retain, use and disclose information associated with my membership or otherwise provided to Littles (M) Sendirian Berhad, Al Futtaim RDC Malaysia Sdn Bhd, Prasan Fashions (M)Sdn Bhd, Ogaan Fashions (M) Sdn Bhd, Meldry Fashion (M) Sdn. Bhd, Gagan (Malaysia) Sdn Bhd, Armaan (M) Sdn Bhd, AFG Asia Regional Business Services Sdn Bhd or any other members of Al Futtaim Group of companies or any Blue Programme partners pursuant to the Blue Programme, including the above-mentioned information and any information relating to my use of the Blue App and/or the Blue Programme.`,
        'Bahrain' : `I grant consent to Al-Futtaim Sons Co. WL.L. to collect, retain, use and disclose information associated with my membership or otherwise provided to Al Futtaim Sons WLL, Orient Insurance (Branch),Al Futtaim Auto & Machinery or any other members of Al Futtaim Group of companies or any Blue Programme partners pursuant to the Blue Programme, including the above-mentioned information and any information relating to my use of the Blue App and/or the Blue Programme.`,
        'Egypt' : `I grant consent to Al Futtaim Egypt for Trading & Services , Marks & Spencer Egypt to collect, retain, use and disclose information associated with my membership or otherwise provided to Alfa Egypt Trading SAE, Orient Takaful Insurance Company (S.A.E.), El Nile Trading & Engg. Co. SAE, Al-Futtaim Misr for Retail S.A.E., Al Futtaim Real Estate, Al-Futtaim Engineering S.A.E,Al Futtaim Egypt Trading & Services SAE or any other members of Al Futtaim Group of companies or any Blue Programme partners pursuant to the Blue Programme, including the above-mentioned information and any information relating to my use of the Blue App and/or the Blue Programme.`,
        'Qatar' : `I grant consent to Hamad and Mohamed AlFuttaim LLC to collect, retain, use and disclose information associated with my membership or otherwise provided to Doha Marketing Services Co. Hamad & Mohamad Al Futtaim WLL or any other members of Al Futtaim Group of companies or any Blue Programme partners pursuant to the Blue Programme, including the above-mentioned information and any information relating to my use of the Blue App and/or the Blue Programme.`
    }
    return (
        <>
            <div className="body_wrapper tandC">
                <div className="wrapper">
                    <p className='title'>Terms of Use</p>
                    <table>
                        <tr>
                            <th>Employee Data</th>                            
                        </tr>
                        <tr>
                            <td>Employee Name</td>
                        </tr>
                        <tr>
                            <td>Employee ID</td>
                        </tr>
                        <tr>
                            <td>Employee Email ID</td>
                        </tr>
                        <tr>
                            <td>Employee Band</td>
                        </tr>
                        <tr>
                            <td>Employee Company name / group</td>
                        </tr>
                        <tr>
                            <td>Employee Date of Joining</td>
                        </tr>
                        <tr>
                            <td>Employee Profile Picture </td>
                        </tr>
                        <tr>
                            <td>Employee Phone Number</td>
                        </tr>
                        <tr>
                            <td>Employment Status</td>
                        </tr>                        
                    </table>
                    <span className='textItem'>{data[country]}</span>
                    <span className='subTextItem'>The consent that you provide for the collection, use and disclosure of your personal data will remain valid until such time it is being withdrawn by you in writing.</span>
                </div>
            </div>
        </>
    );
};

export default TermsAndCondition;