import styles from './Accordion.module.scss';
import React, { Component, useState } from 'react';

const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(false);
  
    return (
        <div className={`${styles.accordionWrap} container`}>
            <div className="accordion-tab">
                <div className="accordion" id="faq-section-tabs">
                    <div className="accordion-item">
                      <h2 className={styles.accordionHeader} id="headingOne" onClick={() => setIsActive(!isActive)}>                        
                        <div className={styles.wrap}>
                            <p className={styles.title}>{title}</p>
                            <span className={styles.icon}>{isActive ? '-' : '+'}</span>
                        </div>
                      </h2>
                      
                      {isActive && 
                      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className={styles.accordionBody}>
                            <p className={styles.title} >{content}</p>
                        </div>
                      </div>
                        }
                    </div>
                </div>
            </div>
        </div>    
    );
  };
  
export default Accordion;
