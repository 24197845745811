import { RouteObject } from "react-router-dom";
import Home from "../pages/Home";
import FAQ from "../pages/faq";

const AuthenticationRoutes: RouteObject = {
    children: [
        {
          path: '',
          element: <Home />
        },{
            path: '/faq',
            element: <FAQ />
        }
      ]
};

export default AuthenticationRoutes;