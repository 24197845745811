
import { REACT_APP_MS_URL, REACT_APP_GET_BLUE, REACT_APP_GET_EMPLOYEE, REACT_APP_POST_EMPLOYEE, REACT_APP_EMP_CODE, REACT_APP_BLUE_CODE,REACT_APP_API_KEY} from "./envConfig";

/**
 * API AUTH CODE
 */

export const X_API_KEY_EMPLOYEE = REACT_APP_EMP_CODE
export const X_API_KEY_BLUE = REACT_APP_BLUE_CODE
export const API_KEY = REACT_APP_API_KEY

/**Endpoints */
export const POST_EMPLOYEE_DETAILS = `${REACT_APP_MS_URL}${REACT_APP_POST_EMPLOYEE}`;
export const GET_EMPLOYEE_DETAILS = `${REACT_APP_MS_URL}${REACT_APP_GET_EMPLOYEE}`;
export const GET_BLUE_DETAILS = `${REACT_APP_MS_URL}${REACT_APP_GET_BLUE}`;
