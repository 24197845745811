import { BrowserCacheLocation, Configuration } from "@azure/msal-browser";
import { REACT_APP_UAS_MSAL_AUTHORITY, REACT_APP_UAS_MSAL_CLIENT_ID, REACT_APP_UAS_MSAL_POST_LOGOUT_REDIRECT_URI, REACT_APP_UAS_MSAL_REDIRECT_URI } from "./envConfig";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const uasMsalConfig: Configuration = {
    auth: {
        clientId: REACT_APP_UAS_MSAL_CLIENT_ID,
        authority: REACT_APP_UAS_MSAL_AUTHORITY,
        redirectUri: REACT_APP_UAS_MSAL_REDIRECT_URI,
        postLogoutRedirectUri: REACT_APP_UAS_MSAL_POST_LOGOUT_REDIRECT_URI
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const scopes = ["User.Read"];

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const msalStorage = () => uasMsalConfig.cache?.cacheLocation === "sessionStorage" ? sessionStorage : localStorage;
