// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Accordion_accordionWrap__VFUz6 {
  margin: 0 auto;
}
.Accordion_accordionWrap__VFUz6 .Accordion_accordionHeader__7AlZv .Accordion_wrap__ZKcCt {
  background: #F3F3F3;
  cursor: pointer;
}
.Accordion_accordionWrap__VFUz6 .Accordion_accordionHeader__7AlZv .Accordion_title__tD2pZ {
  color: #231F20;
  font-family: FranklinGothic URW;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  display: inline-block;
  padding-left: 15px;
  width: 80%;
  margin-top: 1rem;
}
.Accordion_accordionWrap__VFUz6 .Accordion_accordionHeader__7AlZv .Accordion_icon__ukPv- {
  font-size: 16px;
  float: right;
  padding: 18px;
}
.Accordion_accordionWrap__VFUz6 .Accordion_accordionBody__GEM\\+f .Accordion_title__tD2pZ {
  color: #231F20;
  font-family: FranklinGothic URW;
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: 16px;
  display: inline-block;
  padding-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Accordion/Accordion.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;AACQ;EACI,mBAAA;EACA,eAAA;AACZ;AACQ;EACI,cAAA;EACA,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,kBAAA;EACA,UAAA;EACA,gBAAA;AACZ;AACQ;EACI,eAAA;EACA,YAAA;EACA,aAAA;AACZ;AAGQ;EACI,cAAA;EACA,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,kBAAA;AADZ","sourcesContent":[".accordionWrap{    \n    margin: 0 auto;\n    .accordionHeader {\n        .wrap {\n            background : #F3F3F3;\n            cursor: pointer;\n        }\n        .title {\n            color: #231F20;\n            font-family: FranklinGothic URW;\n            font-size: 16px;\n            font-style: normal;\n            font-weight: 600;\n            line-height: 21px;\n            display: inline-block;\n            padding-left: 15px;\n            width: 80%;\n            margin-top: 1rem;\n        }\n        .icon {\n            font-size: 16px;\n            float: right;\n            padding: 18px;            \n        }\n    }\n    .accordionBody {\n        .title {\n            color: #231F20;\n            font-family: FranklinGothic URW;\n            font-size: 14px;\n            font-style: normal;\n            font-weight: 100;\n            line-height: 16px;\n            display: inline-block;\n            padding-left: 15px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordionWrap": `Accordion_accordionWrap__VFUz6`,
	"accordionHeader": `Accordion_accordionHeader__7AlZv`,
	"wrap": `Accordion_wrap__ZKcCt`,
	"title": `Accordion_title__tD2pZ`,
	"icon": `Accordion_icon__ukPv-`,
	"accordionBody": `Accordion_accordionBody__GEM+f`
};
export default ___CSS_LOADER_EXPORT___;
