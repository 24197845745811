import styles from './Breadcrumb.module.scss';
import React, { Component } from 'react';
export const Breadcrumb = (props: { highlight: any; }) => {
    
    return (
        <div className={styles.breadCrumbWrap}>
            <div className={styles.bread}>
                <span className={styles.line}></span>
                <span className={`${styles.number} ${props?.highlight === '1' ? styles.fill : 'noFill'}`}>1</span>
                <span className={styles.text}>Select Company</span>
            </div>
            <div className={styles.bread}>
                <span className={styles.line}></span>
                <span className={`${styles.number} ${props?.highlight === '2' ? styles.fill : 'noFill'}`}>2</span>
                <span className={styles.text}>Login</span>
            </div>
            <div className={styles.bread}>
                <span className={styles.line}></span>
                <span className={`${styles.number} ${props?.highlight === '3' ? styles.fill : 'noFill'}`}>3</span>
                <span className={styles.text}>Employee Validation</span>
            </div>
            <div className={styles.bread}>
                <span className={styles.line}></span>
                <span className={`${styles.number} ${props?.highlight === '4' ? styles.fill : 'noFill'}`}>4</span>
                <span className={styles.text}>Account Info</span>
            </div>
        </div>
    );
};
