import { AuthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Breadcrumb } from '../../components/Breadcrumb';
import { Form } from '../../components/Form';
import React, { useState } from 'react';
import { GET_EMPLOYEE_DETAILS, POST_EMPLOYEE_DETAILS, X_API_KEY_EMPLOYEE } from '../../config/apiConfig';
import { setMsalAd } from '../../store/slices/MsalSlice';
import axiosClient from '../../Services/http_service';
import { encryptData } from 'src/utils/crypto';

const Home = () => {
    type BlueListModel={
        blue: {
            blueId: number,
            bluePhoneNumber: string,
            blueSpousePhoneNumber: string,
            blueEmailId: string,
            blueSpouseEmailId: string,
            uId: string
        },
        employee: {
            employeeId: string,
            employeeName: string,
            employeeEmailId: string,
            employeeCompanyName: string,
            isActive: string,
            isSpouseAllowed: boolean,
            employeeDoJ: string
        }
    }
    type BlueDetailsListModel={
        blue: {
            blueId: number,
            bluePhoneNumber: string,
            blueEmailId: string,
            UID: string
        },
        employee?: {
            employeeId: string
        },
        messages?
    }
    const { instance, accounts } = useMsal();
    const dispatch = useDispatch();
    const activeAccount = accounts?.[0] ?? null;
    const [formData, setFormData] = useState({name: "",empId:"",email: activeAccount?.username,mobNo: "",consent:false});
    const [showFields, setShowFields] = useState([
        {
            'email' : {
                show : true,
                disabledStatus : true
            },
            'empId' : {
                show : true,
                disabledStatus : false
            },
            'name': {
                show : false,
                disabledStatus : false
            },
            'mobNo': {
                show : false,
                disabledStatus : false
            },
            'submitBtn' : {
                show : false,
                disabledStatus : false
            },
            'consent' : {
                show : false,
                disabledStatus : false
            }
        }]
    );
    const [blueResult, setBlueResult] = useState<BlueListModel>();
    const [breadcrumbVal, setBreadcrumbVal] = useState('3');
    const [disableValidateBtn, setDisableValidateBtn] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [blueDetailsResult, setBlueDetailsResult] = useState<BlueDetailsListModel>();
    const [validStatus, setValidStatus] = useState<number>(0);
    const [optionsState, setOptionsState] = useState('');
    let navigate = useNavigate();

    const getBlueData = (index) => {
        setBlueDetailsResult(index);        
        setShowFields([{
            'email' : {
                show : true,
                disabledStatus : true
            },
            'empId' : {
                show : true,
                disabledStatus : true
            },
            'name': {
                show : true,
                disabledStatus : true
            },
            'mobNo': {
                show : true,
                disabledStatus : blueResult?.blue?.bluePhoneNumber ? true : false //toggle them back
            },
            'submitBtn' : {
                show : true,
                disabledStatus : blueResult?.blue?.bluePhoneNumber ? true : false //toggle them back
            },
            'consent' : {
                show : true,
                disabledStatus : false
            }
        }]);
    };
    const handleChange = (event: { target: { name: any; value: any; }; }, disableLinkBtn?) => {
        
        if(disableLinkBtn){
            setDisableValidateBtn(true);
            setShowFields([{
                'email' : {
                    show : true,
                    disabledStatus : true
                },
                'empId' : {
                    show : true,
                    disabledStatus : true
                },
                'name': {
                    show : true,
                    disabledStatus : true
                },
                'mobNo': {
                    show : true,
                    disabledStatus : blueResult?.blue?.bluePhoneNumber ? true : false //toggle them back
                },
                'submitBtn' : {
                    show : true,
                    disabledStatus : blueResult?.blue?.bluePhoneNumber ? true : false //toggle them back
                },
                'consent' : {
                    show : false,
                    disabledStatus : false
                }
            }]);
        }
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };
    const validateForm = () => {
        let isChecked = (document.getElementById('consent') as HTMLInputElement)?.checked;        
        if(isChecked && blueDetailsResult && blueDetailsResult?.messages[0]?.status === 'success'){
            setDisableValidateBtn(false);
        } else {
            setDisableValidateBtn(true);
        }
    };
    const postEmpDetails = async () => {
        let mobileNumber = (document.getElementById('mobNo') as HTMLInputElement).value;
        let emailId = (document.getElementById('email') as HTMLInputElement).value;
        let empId = (document.getElementById('empId') as HTMLInputElement).value;
        let name = (document.getElementById('name') as HTMLInputElement).value;
        let consent = (document.getElementById('consent') as HTMLInputElement).checked;
        let localeId = (document.getElementById('localeId') as HTMLInputElement).value;
        let e = document.getElementById("localeId") as any;
        let localeIdText = e[e.selectedIndex].text.replace(/ *\([^)]*\) */g, "");
        let data = {
            "blue": {
                "blueId": blueDetailsResult?.blue?.blueId,
                "bluePhoneNumber": localeId + mobileNumber.replaceAll("+", ""),
                "blueSpousePhoneNumber": '',
                "blueEmailId": blueDetailsResult?.blue?.blueEmailId,
                "blueSpouseEmailId": '',
                "uId": blueDetailsResult?.blue?.UID
            },
            "employee": {
                "employeeId": empId,
                "employeeName": name,
                "employeeEmailId": emailId,
                "employeeCompanyName": blueResult?.employee?.employeeCompanyName,
                "isActive": blueResult?.employee?.isActive,
                "isSpouseAllowed": blueResult?.employee?.isSpouseAllowed,
                "employeeDoJ": blueResult?.employee?.employeeDoJ,
                "consent" : consent,
                "country" : localeIdText
            }            
        }
        await axiosClient      
            .post(
            `${POST_EMPLOYEE_DETAILS}`, 
            data,
            {},
            true
            )
            .then((response) => {
            if (response?.data?.messages[0]?.status === 'success') {
                navigate('/profile',{
                    state : {
                        mobileNumber : mobileNumber,
                        emailId : emailId
                    }
                });
            } else {
                navigate('/pageNotFound',{
                    state : {
                        mobileNumber : mobileNumber,
                        emailId : emailId
                    }
                });
            }
            })
            .catch((error) => {
                console.log(error)
                navigate('/pageNotFound',{
                    state : {
                        mobileNumber : mobileNumber,
                        emailId : emailId
                    }
                });
                console.log("Post error", error);
            });
    }
    const validateEmp = async () => {
        if(formData?.empId) {
            await axiosClient.get(`${GET_EMPLOYEE_DETAILS}?employeeId=${encodeURIComponent(encryptData(formData?.empId))}`)
                .then((response) => {
                    let result = response?.data;
                    if(result?.messages[0]?.status === 'success'){                        
                        setBlueResult(result);
                        /** verify if email Id in the textbox and the one received 
                         * in response must be same */
                        if((result?.employee?.employeeEmailId && result?.employee?.employeeEmailId.toLowerCase() === activeAccount?.username.toLowerCase()) && result?.employee?.employeeEmailId !== "") {
                            setFormData({
                                name: result?.employee?.employeeName,
                                empId:result?.employee?.employeeId,
                                email: result?.employee?.employeeEmailId,
                                mobNo: result?.blue?.bluePhoneNumber,
                                consent: true
                            });
                            setShowFields([{
                                'email' : {
                                    show : true,
                                    disabledStatus : true
                                },
                                'empId' : {
                                    show : true,
                                    disabledStatus : true
                                },
                                'name': {
                                    show : true,
                                    disabledStatus : true
                                },
                                'mobNo': {
                                    show : true,
                                    disabledStatus : result?.blue?.bluePhoneNumber ? true : false //toggle them back
                                },
                                'submitBtn' : {
                                    show : true,
                                    disabledStatus : result?.blue?.bluePhoneNumber ? true : false //toggle them back
                                },
                                'consent' : {
                                    show : false,
                                    disabledStatus : false
                                }
                            }]);
                            setBreadcrumbVal('4');
                            setErrorMsg('');
                            if(result?.blue?.bluePhoneNumber)
                            setValidStatus(1);
                            setOptionsState(result?.employee?.country);
                        }else{                            
                            setErrorMsg("Invalid employee id");
                        }
                    } else {                        
                        setErrorMsg(result?.messages[0]?.message);
                    }
                })
                .catch((error) => {         
                    console.log(error)           
                    return error?.response ? error.response : error;
                });
        } else {            
            setErrorMsg('Please enter employee Id');
        }        
    }
    const signOut = () => {
        instance.logoutPopup({
            mainWindowRedirectUri: '/', // redirects the top level app after logout
            account: accounts?.[0] ?? null
        }).then((res) => {
            dispatch(
                setMsalAd(null)
            );
        })
    }
    return (
        <>
            <AuthenticatedTemplate>
                {activeAccount ? (
                    <div className="body_wrapper">
                        <div className="wrapper">
                            <p className="heading">Link your employee account to Blue</p>
                            <Breadcrumb highlight={breadcrumbVal}/>                            
                            <Form 
                                formData={formData} 
                                activeAccountData={activeAccount}
                                handleChange={handleChange}
                                validateForm={validateForm}
                                getBlueData={getBlueData}
                                showFields={showFields}
                                errorMsg={errorMsg}
                                setErrorMsg={setErrorMsg}
                                validStatus={validStatus}
                                optionsState={optionsState}
                                />
                            <div className="mob-bottom-wrapper">
                                <Button 
                                    disabled={(breadcrumbVal === '4') ? disableValidateBtn : false}
                                    onClick={(breadcrumbVal === '4') ? postEmpDetails : validateEmp} 
                                    className={`${disableValidateBtn ? 'errorInBtn' : ''} custom-btn`}>{(breadcrumbVal === '4') ? 'Link Account' : 'Validate'}
                                </Button>
                                <Button 
                                    onClick={signOut} 
                                    className='custom-btn sign-out-btn'>Sign Out
                                </Button>
                                <div className="needHelpWrapper">
                                    <p className="need-help">Need help?</p>
                                    <a href='/faq' className="need-help-link" target="_blank">FAQs</a>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </AuthenticatedTemplate>
                {/* Commented as not required as of now */ }
                {/* <AuthenticatedTemplate>
                    {activeAccount ? (
                        <Container>
                            <IdTokenData idTokenClaims={activeAccount.idTokenClaims} />
                        </Container>
                    ) : null}
                </AuthenticatedTemplate> */}
            
        </>
    );
};

export default Home;